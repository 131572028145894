<template>
  <div>
    <c-table
      ref="table"
      title="작업분류표"
      :merge="grid.merge"
      :columns="gridColumns"
      :data="investigation.sopTable"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
    >
      <template v-slot:customArea="{ props, col }">
        <span :class="props.row[col.name] === 'O' ? 'text-primary' : 'text-red'">
          <b>{{props.row[col.name]}}</b>
        </span>
      </template>
      <!-- eslint-disable -->
      <template v-slot:customHeader="{ props, col }">
        <q-img :src="`${col.src}`"></q-img>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'worksheet',
  props: {
    investigation: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        plantCd: '',  // 사업장코드
        investigationPlanName: '',  // 조사계획명
        year: '',  // 심사년도
        heaInvestigationStepCd: '',  // 진행상태
        investigationStartDate: '',  // 조사시작일
        investigationEndDate: '',  // 조사종료일
        period: [],
        deptCd: '',  // 담당자 부서
        userId: '',  // 담당자 id
        investigationDeptCd: '',  // 조사부서
        agency: '',  // 조사기관
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sops: [], // 단위작업
        sopTable: [], // 작업분류표
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
      }),
    },
    heavyWorks: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processCd', },
          { index: 1, colName: 'sopName', },
        ],
        headerItems: [],
        defaultColumns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '단위작업',
            align: 'left',
            sortable: false,
            style: 'width:170px',
          },
          {
            name: 'a',
            field: 'a',
            label: '구분',
            style: 'width:80px',
            child: [
              {
                name: 'b',
                field: 'b',
                label: '노출시간',
                style: 'width:80px',
                child: [
                  {
                    name: 'c',
                    field: 'c',
                    label: '노출빈도',
                    style: 'width:80px',
                    child: [
                      {
                        name: 'd',
                        field: 'd',
                        label: '신체부위',
                        style: 'width:80px',
                        child: [
                          {
                            name: 'e',
                            field: 'e',
                            label: '작업자세<br/>및 내용',
                            style: 'width:80px',
                            child: [
                              {
                                name: 'f',
                                field: 'f',
                                label: '무게',
                                align: 'center',
                                sortable: false,
                                style: 'width:80px',
                                // type: 'custom',
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 100) + 'px' : '500px'
    },
    gridColumns() {
      let _cols = this.$_.cloneDeep(this.grid.defaultColumns);
      if (this.heavyWorks && this.heavyWorks.length > 0) {
        this.$_.forEach(this.heavyWorks, item => {
          /**
           * 1. 구분
           * 2. 노출시간
           * 3. 노출빈도
           * 4. 신체부위
           * 5. 작업자세 및 내용
           * 6. 무게
           */
          let _col = {
            name: `heavyWorkName${item.heaHeavyWorkNo}`,
            field: `heavyWorkName${item.heaHeavyWorkNo}`,
            label: item.heavyWorkName,
            style: 'width:100px',
            headerCustom: true,
            src: item.src,
            child: [
              {
                name: `exposureTime${item.heaHeavyWorkNo}`,
                field: `exposureTime${item.heaHeavyWorkNo}`,
                label: item.exposureTime,
                style: 'width:100px',
                child: [
                  {
                    name: `exopsureCnt${item.heaHeavyWorkNo}`,
                    field: `exopsureCnt${item.heaHeavyWorkNo}`,
                    label: item.exopsureCnt,
                    style: 'width:100px',
                    child: [
                      {
                        name: `bodyDesc${item.heaHeavyWorkNo}`,
                        field: `bodyDesc${item.heaHeavyWorkNo}`,
                        label: item.bodyDesc,
                        style: 'width:100px',
                        child: [
                          {
                            name: `positionDesc${item.heaHeavyWorkNo}`,
                            field: `positionDesc${item.heaHeavyWorkNo}`,
                            label: item.positionDesc,
                            style: 'width:100px',
                            child: [
                              {
                                name: `heaHeavyWorkNo${item.heaHeavyWorkNo}`,
                                field: `heaHeavyWorkNo${item.heaHeavyWorkNo}`,
                                label: item.weightDesc,
                                align: 'center',
                                sortable: false,
                                style: 'width:100px',
                                type: 'custom',
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
            ]
          };
          _cols.push(_col)
        })
      }
      return _cols
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>